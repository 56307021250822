
















































































































































































































































































































































































































































// 订单头部筛选未完成
import { Component, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import { apiOrderLists } from '@/api/order/order'
import {
    apiFaceSheetTemplateLists,
    apiFaceSheetSenderLists,
    apiFaceSheetPrint
} from '@/api/application/express'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        LsDialog,
        DatePicker,
        ExportData
    }
})
export default class GooRechargeRecord extends Vue {
    /** S Data **/
    $refs!: { paneTable: any }

    progress = 0 //进度条
    index = 0 //打印到第几份

    dialogTableVisible = false

    apiOrderLists = apiOrderLists

    selectIds: any = []

    senderTemplate: Array<object> = []
    faceSheetTemplate: Array<object> = []

    form = {
        template_id: '',
        sender_id: ''
    }

    SearchData = {
        order_sn: '',
        user_info: '',
        goods_name: '',
        contact_info: '',
        start_time: '',
        end_time: '',
        order_status: 1
    }

    pager = new RequestPaging()

    /** E Data **/

    /** S Method **/

    format(progress: number) {
        return this.index + '/' + this.selectIds.length
    }

    // 打印以及批量打印
    prints(arr: any) {
        if (Array.isArray(arr) == true) {
            this.selectIds = arr
            arr = this.selectIds
        } else {
            arr = this.selectIds
        }
        if (arr.length <= 0) {
            return this.$message.error('请选择打印的订单')
        }
        this.dialogTableVisible = true
        const index = this.index

        // 递归出口
        if (arr[index] == undefined) {
            this.clearStatus()
            return
        }

        apiFaceSheetPrint({
            order_ids: [arr[index]],
            ...this.form
        })
            .then(res => {
                if (res.code != 1) {
                    this.clearStatus()
                    return
                }
                setTimeout(() => {
                    this.index += 1
                    const res = 100 / this.selectIds.length
                    this.progress = res * this.index
                    this.prints(arr) //递归此函数
                }, 200)
            })
            .catch(err => {
                this.clearStatus()
            })
    }

    // 清空状态
    clearStatus() {
        this.dialogTableVisible = false
        this.index = 0
        this.selectIds = []
        ;(this.$refs.paneTable as any).clearSelection()
        this.progress = 0
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }

    // 全选
    selectAll() {
        ;(this.$refs.paneTable as any).toggleAllSelection()
    }

    // 电子面单模版
    async getFaceSheetTemplateFunc() {
        const res = await apiFaceSheetTemplateLists({})
        this.faceSheetTemplate = res.lists
    }

    // 发件人模版
    async getSenderTemplateFunc() {
        const res = await apiFaceSheetSenderLists({})
        this.senderTemplate = res.lists
    }

    // 获取数据
    getBatchLists(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiOrderLists,
            params: {
                ...this.SearchData,
                delivery_type: 1
            }
        })
    }

    // 重置搜索记录
    resetSearchData() {
        Object.keys(this.SearchData).map(key => {
            this.$set(this.SearchData, key, '')
        })
        this.getBatchLists()
    }

    /** E Method **/

    created() {
        this.getBatchLists()
        this.getSenderTemplateFunc()
        this.getFaceSheetTemplateFunc()
    }
}
